<template>
  <div class="my_courses_block">
    <div class="my_courses_header_block">
      <div class="my_courses_header_container">
        <div class="my_courses_header_label">
          Мои курсы
        </div>
      </div>
    </div>
    <div class="my_courses_main_block">
      <div class="my_courses_main_container">
        <div class="my_courses_active_filter_container">
          <div class="my_courses_filter_label">Сортировка и фильтр</div>
          <div class="my_courses_status">
            <router-link to="/my_courses_active" class="my_courses_status_active">
              Активные
            </router-link>
            <router-link to="/my_courses" class="my_courses_status_done">
              Пройденные
            </router-link>
            <div class="my_courses_status_all">
              Все
            </div>
          </div>
          <div class="my_courses_list_sorting">
            <div class="my_courses_list_sorting_label">Сортировка</div>
            <div class="my_courses_list_radio_block">
              <div class="my_courses_radio_item">
                <input type="radio" name="sort" id="newest" class="my_courses_list_radio"/>
                <label for="newest" class="my_courses_radio_label">По проценту прохождения</label>
              </div>
              <div class="my_courses_radio_item">
                <input type="radio" name="sort" id="popular" class="my_courses_list_radio"/>
                <label for="popular" class="my_courses_radio_label">Окончанию</label>
              </div>
            </div>
          </div>
          <div class="my_courses_list_sorting">
            <div class="my_courses_list_sorting_label">Продолжительность курса</div>
            <div class="my_courses_list_radio_block">
              <div class="my_courses_radio_item">
                <input type="radio" name="filter" id="8" class="my_courses_list_radio"/>
                <label for="8" class="my_courses_radio_label">8</label>
              </div>
              <div class="my_courses_radio_item">
                <input type="radio" name="filter" id="36" class="my_courses_list_radio"/>
                <label for="36" class="my_courses_radio_label">36</label>
              </div>
              <div class="my_courses_radio_item">
                <input type="radio" name="filter" id="40" class="my_courses_list_radio"/>
                <label for="40" class="my_courses_radio_label">40</label>
              </div>
              <div class="my_courses_radio_item">
                <input type="radio" name="filter" id="72" class="my_courses_list_radio"/>
                <label for="72" class="my_courses_radio_label">72</label>
              </div>
            </div>
          </div>
        </div>
        <div class="my_courses_active_list">
          <div class="courses_list_item">
            <div class="courses_list_item_img">
            </div>
            <div class="courses_list_item_desc">
              <div class="courses_list_item_desc_text">
                <div class="courses_list_item_title">Финансовая граммотность и как её повысить</div>
                <div class="courses_list_item_bar">
                  <div class="courses_list_item_back"></div>
                  <div class="courses_list_item_process">В процессе</div>
                  <div class="courses_list_item_result">40%</div>
                </div>
                <div class="courses_list_item_date">
                  <div class="my_courses_list_item_start">Начало: 22.04.22</div>
                  <div class="my_courses_list_item_between">|</div>
                  <div class="my_courses_list_item_end">Окончание: 30.05.22</div>
                </div>
                <div class="my_courses_list_item_action">
                  <a class="my_courses_list_item_continue">
                    Продолжить
                    <div class="my_courses_list_item_arrow"></div>
                  </a>
                  <div class="my_courses_list_item_unsubscribe">Отписаться</div>
                </div>
              </div>
            </div>
          </div>
          <div class="courses_list_item_90">
            <div class="courses_list_item_img">
            </div>
            <div class="courses_list_item_desc">
              <div class="courses_list_item_desc_text">
                <div class="courses_list_item_title">Финансовая граммотность и как её повысить</div>
                <div class="courses_list_item_bar">
                  <div class="courses_list_item_back"></div>
                  <div class="courses_list_item_process">В процессе</div>
                  <div class="courses_list_item_result">90%</div>
                </div>
                <div class="courses_list_item_date">
                  <div class="my_courses_list_item_start">Начало: 22.04.22</div>
                  <div class="my_courses_list_item_between">|</div>
                  <div class="my_courses_list_item_end">Окончание: 30.05.22</div>
                </div>
                <div class="my_courses_list_item_action">
                  <a class="my_courses_list_item_continue">
                    <div>Продолжить</div>
                    <div class="my_courses_list_item_arrow"></div>
                  </a>
                  <div class="my_courses_list_item_unsubscribe">Отписаться</div>
                </div>
              </div>
            </div>
          </div>
          <div class="courses_list_item_10">
            <div class="courses_list_item_img">
            </div>
            <div class="courses_list_item_desc">
              <div class="courses_list_item_desc_text">
                <div class="courses_list_item_title">Финансовая граммотность и как её повысить</div>
                <div class="courses_list_item_bar">
                  <div class="courses_list_item_back"></div>
                  <div class="courses_list_item_process">В процессе</div>
                  <div class="courses_list_item_result">10%</div>
                </div>
                <div class="courses_list_item_date">
                  <div class="my_courses_list_item_start">Начало: 22.04.22</div>
                  <div class="my_courses_list_item_between">|</div>
                  <div class="my_courses_list_item_end">Окончание: 30.05.22</div>
                </div>
                <div class="my_courses_list_item_action">
                  <a class="my_courses_list_item_continue">
                    <div>Продолжить</div>
                    <div class="my_courses_list_item_arrow"></div>
                  </a>
                  <div class="my_courses_list_item_unsubscribe">Отписаться</div>
                </div>
              </div>
            </div>
          </div>
          <div class="courses_list_item">
            <div class="courses_list_item_img">
            </div>
            <div class="courses_list_item_desc">
              <div class="courses_list_item_desc_text">
                <div class="courses_list_item_title">Финансовая граммотность и как её повысить</div>
                <div class="courses_list_item_bar">
                  <div class="courses_list_item_back"></div>
                  <div class="courses_list_item_process">В процессе</div>
                  <div class="courses_list_item_result">40%</div>
                </div>
                <div class="courses_list_item_date">
                  <div class="my_courses_list_item_start">Начало: 22.04.22</div>
                  <div class="my_courses_list_item_between">|</div>
                  <div class="my_courses_list_item_end">Окончание: 30.05.22</div>
                </div>
                <div class="my_courses_list_item_action">
                  <a class="my_courses_list_item_continue">
                    Продолжить
                    <div class="my_courses_list_item_arrow"></div>
                  </a>
                  <div class="my_courses_list_item_unsubscribe">Отписаться</div>
                </div>
              </div>
            </div>
          </div>
          <div class="courses_list_item_90">
            <div class="courses_list_item_img">
            </div>
            <div class="courses_list_item_desc">
              <div class="courses_list_item_desc_text">
                <div class="courses_list_item_title">Финансовая граммотность и как её повысить</div>
                <div class="courses_list_item_bar">
                  <div class="courses_list_item_back"></div>
                  <div class="courses_list_item_process">В процессе</div>
                  <div class="courses_list_item_result">90%</div>
                </div>
                <div class="courses_list_item_date">
                  <div class="my_courses_list_item_start">Начало: 22.04.22</div>
                  <div class="my_courses_list_item_between">|</div>
                  <div class="my_courses_list_item_end">Окончание: 30.05.22</div>
                </div>
                <div class="my_courses_list_item_action">
                  <a class="my_courses_list_item_continue">
                    <div>Продолжить</div>
                    <div class="my_courses_list_item_arrow"></div>
                  </a>
                  <div class="my_courses_list_item_unsubscribe">Отписаться</div>
                </div>
              </div>
            </div>
          </div>
          <div class="courses_list_item_10">
            <div class="courses_list_item_img">
            </div>
            <div class="courses_list_item_desc">
              <div class="courses_list_item_desc_text">
                <div class="courses_list_item_title">Финансовая граммотность и как её повысить</div>
                <div class="courses_list_item_bar">
                  <div class="courses_list_item_back"></div>
                  <div class="courses_list_item_process">В процессе</div>
                  <div class="courses_list_item_result">10%</div>
                </div>
                <div class="courses_list_item_date">
                  <div class="my_courses_list_item_start">Начало: 22.04.22</div>
                  <div class="my_courses_list_item_between">|</div>
                  <div class="my_courses_list_item_end">Окончание: 30.05.22</div>
                </div>
                <div class="my_courses_list_item_action">
                  <a class="my_courses_list_item_continue">
                    <div>Продолжить</div>
                    <div class="my_courses_list_item_arrow"></div>
                  </a>
                  <div class="my_courses_list_item_unsubscribe">Отписаться</div>
                </div>
              </div>
            </div>
          </div>
          <div class="courses_list_item">
            <div class="courses_list_item_img">
            </div>
            <div class="courses_list_item_desc">
              <div class="courses_list_item_desc_text">
                <div class="courses_list_item_title">Финансовая граммотность и как её повысить</div>
                <div class="courses_list_item_bar">
                  <div class="courses_list_item_back"></div>
                  <div class="courses_list_item_process">В процессе</div>
                  <div class="courses_list_item_result">40%</div>
                </div>
                <div class="courses_list_item_date">
                  <div class="my_courses_list_item_start">Начало: 22.04.22</div>
                  <div class="my_courses_list_item_between">|</div>
                  <div class="my_courses_list_item_end">Окончание: 30.05.22</div>
                </div>
                <div class="my_courses_list_item_action">
                  <a class="my_courses_list_item_continue">
                    Продолжить
                    <div class="my_courses_list_item_arrow"></div>
                  </a>
                  <div class="my_courses_list_item_unsubscribe">Отписаться</div>
                </div>
              </div>
            </div>
          </div>
          <div class="courses_list_item_empty">
            <div class="courses_list_item_cover">
              <div class="courses_list_item_cover_label">Записаться на новый курс</div>
            </div>
            <div class="courses_list_item_img">
            </div>
            <div class="courses_list_item_desc">
              <div class="courses_list_item_desc_text">
                <div class="courses_list_item_title">Финансовая граммотность </div>
                <div class="courses_list_item_bar">
                  <div class="courses_list_item_back"></div>
                  <div class="courses_list_item_process">В процессе</div>
                  <div class="courses_list_item_result">40%</div>
                </div>
                <div class="courses_list_item_date">
                  <div class="my_courses_list_item_start">Начало: 22.04.22</div>
                  <div class="my_courses_list_item_between">|</div>
                  <div class="my_courses_list_item_end">Окончание: 30.05.22</div>
                </div>
                <div class="my_courses_list_item_action">
                  <a class="my_courses_list_item_continue">
                    Продолжить
                    <div class="my_courses_list_item_arrow"></div>
                  </a>
                  <div class="my_courses_list_item_unsubscribe">Отписаться</div>
                </div>
              </div>
            </div>
          </div>
          <div class="courses_list_item_empty">
            <div class="courses_list_item_cover">
              <div class="courses_list_item_cover_label">Записаться на новый курс</div>
            </div>
            <div class="courses_list_item_img">
            </div>
            <div class="courses_list_item_desc">
              <div class="courses_list_item_desc_text">
                <div class="courses_list_item_title">Финансовая граммотность </div>
                <div class="courses_list_item_bar">
                  <div class="courses_list_item_back"></div>
                  <div class="courses_list_item_process">В процессе</div>
                  <div class="courses_list_item_result">40%</div>
                </div>
                <div class="courses_list_item_date">
                  <div class="my_courses_list_item_start">Начало: 22.04.22</div>
                  <div class="my_courses_list_item_between">|</div>
                  <div class="my_courses_list_item_end">Окончание: 30.05.22</div>
                </div>
                <div class="my_courses_list_item_action">
                  <a class="my_courses_list_item_continue">
                    Продолжить
                    <div class="my_courses_list_item_arrow"></div>
                  </a>
                  <div class="my_courses_list_item_unsubscribe">Отписаться</div>
                </div>
              </div>
            </div>
          </div>
          <div class="courses_pagination">
            <div>
              <!--                  <b-pagination v-model="currentPage" pills :total-rows="rows"></b-pagination>-->
              <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  prev-text="Предыдущая страница"
                  next-text="Следующая страница"
                  pills
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MyCourses"
}
</script>

<style scoped>

</style>
